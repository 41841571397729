import type { SVGProps } from 'react';

export default function ArrowLeftIcon(props: SVGProps<never>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#64748B'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <path d='M14.6667 9.33331L8.00008 16L14.6667 22.6666' />
      <path d='M8 16L24 16' />
    </svg>
  );
}

ArrowLeftIcon.displayName = 'ArrowLeftIcon';
