'use client';

import { getFullUrl } from '@/app/lib/utils/img';
import { Testimonial } from '@/app/ui/components/views/home/testimonial';
import { useIsVisible } from '@/app/ui/hooks/use-is-visible';
import { useScreenDetector } from '@/app/ui/hooks/use-screen-detector';
import React, { useRef } from 'react';

export const TestimonialsContainer = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenDetector();
  const { isVisible } = useIsVisible(ref, isMobile ? { threshold: 0.4, rootMargin: '200px' } : { rootMargin: '-100px' }, true);

  return (
    <div ref={ref} className='grid grid-cols-1 gap-12 md:grid-cols-2 lg:gap-20 2xl:grid-cols-4'>
      <Testimonial
        name='Inga Gościńska'
        profession='Senior Product Designer, Ibexa'
        text={`"Tailframes is a designer's dream come true. The attention to detail in each component lets me create sleek interfaces effortlessly."`}
        avatarProps={{ src: getFullUrl('/images/profiles/ig-profile.webp') }}
        isVisible={isVisible}
      />
      <Testimonial
        name='Maciej Kot'
        profession='Backend Developer, Codibly'
        text={`"The well-crafted components and easy-to-use code snippets make it seamless to integrate into our projects. It's a time-saver for backend developers aiming for efficiency and clean integration."`}
        avatarProps={{ src: getFullUrl('/images/profiles/mk-profile.webp') }}
        isVisible={isVisible}
      />
      <Testimonial
        name='Michał Caba'
        profession='Design System Manager, Morele'
        text={`"It's is a game-changer! The pre-designed components and preview features save me time and can make my projects visually stunning."`}
        avatarProps={{ src: getFullUrl('/images/profiles/mc-profile.webp') }}
        isVisible={isVisible}
      />
      <Testimonial
        name='Tomasz Mańka'
        profession='Senior Frontend Developer, Codibly'
        text={`"Tailframes significantly accelerates frontend development. Well-documented code snippets and seamless React integration make projects more efficient and maintainable."`}
        avatarProps={{ src: getFullUrl('/images/profiles/tm-profile.webp') }}
        isVisible={isVisible}
      />
    </div>
  );
};
