'use client';

import { getFullUrl } from '@/app/lib/utils/img';
import { clsxMerge } from '@/app/lib/utils/styles';
import ArrowLeftIcon from '@/app/ui/assets/icons/arrow-left-icon';
import ArrowRightIcon from '@/app/ui/assets/icons/arrow-right-icon';
import FlagIcon from '@/app/ui/assets/icons/flag-icon';
import SearchIcon from '@/app/ui/assets/icons/search-icon';
import { useDebounce } from '@/app/ui/hooks/use-debounce';
import { useIsVisible } from '@/app/ui/hooks/use-is-visible';
import { useScreenDetector } from '@/app/ui/hooks/use-screen-detector';
import { NavSectionType } from '@/app/ui/navigation/config';
import { getNavSectionItemHref, getNavSectionItemHrefByName } from '@/app/ui/navigation/utils';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import { Avatar } from 'tailframes-components/components/avatar';
import { Badge } from 'tailframes-components/components/badge';
import { Button } from '@/app/ui/components/atoms/button';
import { Input } from 'tailframes-components/components/input';
import { Rate } from 'tailframes-components/components/rate';
import { Tag } from 'tailframes-components/components/tag';
import { Switch } from '@/app/ui/components/atoms/switch';
import { Radio } from 'tailframes-components/components/radio/radio';

getNavSectionItemHref(NavSectionType.Components, 0);

const COMPONENTS = [
  {
    component: <Avatar elevated src={getFullUrl('/images/avatar.webp')} />,
    label: 'Avatar',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Avatar'),
  },
  {
    component: <Badge size='large'>100</Badge>,
    label: 'Badge',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Badge'),
  },
  {
    component: <Button size='large'>Button</Button>,
    label: 'Button',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Button'),
  },
  {
    label: 'Switch',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Switch'),
    component: <Switch id='switch' name='switch' defaultChecked />,
  },
  {
    label: 'Radio',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Radio'),
    component: <Radio id='radio' name='radio' label='Radio' labelProps={{ className: 'text-white' }} />,
  },
  {
    component: <Rate value={3} label='3.0/5.0' labelProps={{ className: 'text-white' }} />,
    label: 'Rate',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Rate'),
  },
  {
    component: (
      <Input
        id='search'
        placeholder='Search'
        startAdornment={<SearchIcon className='stroke-inherit' />}
        type='email'
        onClick={e => {
          e.preventDefault();
        }}
      />
    ),
    label: 'Input',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Input'),
  },
  {
    component: <Tag startAdornment={<FlagIcon className='size-[18px]' />}>Tag</Tag>,
    label: 'Tag',
    href: getNavSectionItemHrefByName(NavSectionType.Components, 'Tag'),
  },
];

export default function ComponentsCarousel() {
  const ref = useRef<HTMLDivElement>(null);
  const { isDesktop } = useScreenDetector();
  const { isVisible } = useIsVisible(ref, !isDesktop ? { threshold: 0, rootMargin: '-20px' } : { threshold: 0.5 }, true);
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const debouncedCurrentComponentIndex = useDebounce(currentComponentIndex, 300);
  const isChangingComponent = debouncedCurrentComponentIndex !== currentComponentIndex;
  const componentChangeDirection = debouncedCurrentComponentIndex > currentComponentIndex;

  return (
    <div
      className={clsxMerge('flex w-full max-w-screen-xl flex-col gap-16 overflow-hidden opacity-0 transition-opacity duration-300 ease-in xl:max-w-none', {
        'opacity-100': isVisible,
      })}
    >
      <div className='relative mx-auto flex w-full select-none justify-center text-left md:max-w-screen-xl'>
        <div className='max-w-lg text-center text-4xl font-semibold text-white md:text-5xl'>Unlock the Power of Tailwind Components</div>
        <div className='absolute bottom-0 right-0 z-20 hidden items-end gap-7 xl:flex'>
          <ArrowLeftIcon
            className='cursor-pointer stroke-white'
            onClick={() => {
              !isChangingComponent && setCurrentComponentIndex(currentComponentIndex - 1);
            }}
          />
          <ArrowRightIcon
            className='cursor-pointer stroke-white'
            onClick={() => {
              !isChangingComponent && setCurrentComponentIndex(currentComponentIndex + 1);
            }}
          />
        </div>
      </div>
      <div
        className={clsxMerge('z-10 flex select-none flex-nowrap items-center gap-6 overflow-x-auto opacity-100', {
          'transition-all duration-300 ease-in-out': isChangingComponent,
          'animate-shake': !isDesktop && isVisible,
        })}
        ref={ref as never}
        style={{ transform: isChangingComponent ? (componentChangeDirection ? `translateX(384px)` : `translateX(-384px)`) : '' }}
      >
        {(!isDesktop
          ? COMPONENTS
          : new Array(7).fill(undefined).map((_, index) => COMPONENTS[(COMPONENTS.length + debouncedCurrentComponentIndex + index) % COMPONENTS.length])
        ).map(({ component, label, href }, index) => (
          <a
            key={label}
            className={clsxMerge(
              `relative flex h-[237px] min-w-[360px] items-center justify-center overflow-hidden rounded-xl border border-blue-400 border-opacity-30 opacity-100 transition-opacity duration-200 ease-in`,
              { 'invisible absolute w-0 opacity-0': isDesktop && (index <= 0 || index >= 6) }
            )}
            href={href}
          >
            <span className='absolute left-4 top-4 z-10 text-sm font-medium text-white'>{label}</span>
            <div className='z-10'>{component}</div>
            <Image src='/images/components-section-bg.webp' role='presentation' alt='' fill loading='lazy' priority={false} quality={100} sizes='100vw' />
          </a>
        ))}
      </div>
    </div>
  );
}
