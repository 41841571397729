'use client';

import { clsxMerge } from '@/app/lib/utils/styles';
import { useIsVisible } from '@/app/ui/hooks/use-is-visible';
import { useScreenDetector } from '@/app/ui/hooks/use-screen-detector';
import type { ReactNode } from 'react';
import { Children, cloneElement, isValidElement, useRef } from 'react';

interface Props {
  className: string;
  children: ReactNode;
}

export default function MainSectionContainer({ className, children }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenDetector();
  const { isVisible, direction } = useIsVisible(ref, isMobile ? { threshold: 0.4, rootMargin: '-60px' } : { threshold: 0.6 });

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return cloneElement(child, { ...child.props, isVisible: isVisible || direction === 'down' });
    }

    return child;
  });

  return (
    <div
      data-testid='section-container'
      ref={ref}
      className={clsxMerge('mb-12 flex w-full max-w-screen-xl items-center justify-between gap-12 lg:flex-row lg:gap-32', className)}
    >
      {childrenWithProps as ReactNode}
    </div>
  );
}
