'use client';

import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

export function useIsVisible(ref: MutableRefObject<HTMLDivElement | null>, options?: IntersectionObserverInit, keepVisible?: boolean) {
  const [direction, setDirection] = useState<'up' | 'down'>();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (keepVisible ? entry.isIntersecting : true) {
        setIntersecting(entry.isIntersecting);
      }

      if (entry.boundingClientRect.top < 0) {
        if (entry.isIntersecting) {
          setDirection('up');
        } else {
          setDirection('down');
        }
      }

      if (keepVisible ? entry.isIntersecting : true) {
        setIntersecting(entry.isIntersecting);
      }
    }, options);

    ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [keepVisible, options, ref]);

  return { isVisible: isIntersecting, direction };
}
