import type { NavSectionType } from '@/app/ui/navigation/config';
import { navSections } from '@/app/ui/navigation/config';

export const getNavSection = (type: NavSectionType) => navSections.find(s => s.type === type);

export const getNavSectionItemHref = (type: NavSectionType, index: number) => {
  const navSection = getNavSection(type);
  if (!navSection?.items[index]) {
    throw new Error(`Invalid nav section: ${type}`);
  }

  return `${navSection.href}/${navSection.items[index].href}`;
};

export const getNavSectionItemHrefByName = (type: NavSectionType, name: string) => {
  const navSection = getNavSection(type);
  const item = navSection?.items.find(i => i.name === name);
  if (!navSection || !item) {
    throw new Error(`Invalid nav section: ${type}`);
  }

  return `${navSection.href}${item.href}`;
};

export const getPrevNavSectionItem = (type: NavSectionType, currentSectionName: string) => {
  const navSection = getNavSection(type);
  const currentSectionIndex = navSection?.items.findIndex(i => i.name === currentSectionName);
  if (!navSection || currentSectionIndex === undefined) {
    return null;
  }

  return navSection.items[currentSectionIndex - 1];
};

export const getNextNavSectionItem = (type: NavSectionType, currentSectionItemName: string) => {
  const navSection = getNavSection(type);
  const currentSectionIndex = navSection?.items.findIndex(i => i.name === currentSectionItemName);
  if (!navSection || currentSectionIndex === undefined) {
    return null;
  }

  return navSection.items[currentSectionIndex + 1];
};
