'use client';

import { clsxMerge } from '@/app/lib/utils/styles';
import type { ReactNode } from 'react';
import React from 'react';

interface SectionProps {
  title: ReactNode;
  subtitle: ReactNode;
  className?: string;
  content?: ReactNode;
  header?: ReactNode;
  isVisible?: boolean;
}

export default function MainSection({ title, subtitle, className, content, header, isVisible }: SectionProps) {
  return (
    <section
      className={clsxMerge(
        'flex-2 flex max-w-md translate-y-1/2 flex-col gap-8 opacity-0 transition-[opacity,transform] duration-700 ease-in-out md:duration-1000',
        className,
        {
          'translate-y-0 opacity-100': isVisible,
        }
      )}
    >
      {header}
      <h2 className='text-4xl font-semibold text-white max-[360px]:text-4xl md:text-5xl lg:text-6xl'>{title}</h2>
      <p className='text-slate-300'>{subtitle}</p>
      {content}
    </section>
  );
}
