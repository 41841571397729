import type { SVGProps } from 'react';

export default function FlagIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      stroke='#1D4ED8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      {...props}
    >
      <path d='M3.768 9.35V15m0-5.65c1.373-.643 2.376-.85 3.42-.704.512.071.999.228 1.826.585 1.222.53 1.761.652 2.74.622.59-.018.854-.058 1.312-.197.372-.114.67-.242.946-.407l.238-.143V6.36c0-1.511-.011-2.743-.026-2.737l-.314.149a6.192 6.192 0 0 1-1.417.432c-.468.078-1.377.077-1.825-.001-.49-.086-1.032-.263-1.825-.594-.803-.336-1.19-.47-1.558-.54a5 5 0 0 0-1.018-.062c-.615.052-1.261.238-1.978.57l-.529.246-.009 2.77c-.004 1.524.003 2.766.018 2.759Z' />
    </svg>
  );
}

FlagIcon.displayName = 'FlagIcon';
