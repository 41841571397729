import type { SVGProps } from 'react';

export default function ArrowRightIcon(props: SVGProps<never>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#64748B'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <path d='M17.3333 22.6667L23.9999 16L17.3333 9.33335' />
      <path d='M24 16L8 16' />
    </svg>
  );
}

ArrowRightIcon.displayName = 'ArrowRightIcon';
