import type { SVGProps } from 'react';

export default function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <g>
        <path
          id='Vector'
          d='M20 20C18.5421 18.5421 14.6115 14.6115 14.6115 14.6115M16.4322 10.2161C16.4322 13.6491 13.6491 16.4322 10.2161 16.4322C6.78304 16.4322 4 13.6491 4 10.2161C4 6.78304 6.78304 4 10.2161 4C13.6491 4 16.4322 6.78304 16.4322 10.2161Z'
        />
      </g>
    </svg>
  );
}

SearchIcon.displayName = 'SearchIcon';
