'use client';

import { clsxMerge } from '@/app/lib/utils/styles';
import { useIsVisible } from '@/app/ui/hooks/use-is-visible';
import { useScreenDetector } from '@/app/ui/hooks/use-screen-detector';
import Image from 'next/image';
import React, { useRef } from 'react';

export const StarsBackgroundImage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenDetector();
  const { isVisible } = useIsVisible(ref, isMobile ? { threshold: 0.1 } : { threshold: 0.2 });

  return (
    <Image
      ref={ref as never}
      src='/images/stars-bg.webp'
      alt=''
      className={clsxMerge(
        'absolute inset-x-0 -top-1/4 -z-20 max-h-[900px] w-full min-w-[1440px] -translate-x-1/2 opacity-0 transition-opacity duration-[2000ms] ease-in-out md:translate-x-0',
        {
          'opacity-100': isVisible,
        }
      )}
      width={2880}
      height={1711}
      quality={100}
      sizes='100vw'
      loading='lazy'
      priority={false}
      role='presentation'
    />
  );
};
