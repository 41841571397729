'use client';

import { clsxMerge } from '@/app/lib/utils/styles';
import { useEffect, useRef } from 'react';
import type { AvatarProps } from 'tailframes-components/components/avatar';
import { Avatar } from 'tailframes-components/components/avatar';
import { Rate } from 'tailframes-components/components/rate';

interface Props {
  text: string;
  avatarProps: AvatarProps;
  name: string;
  profession: string;
  isVisible?: boolean;
}

export const Testimonial = ({ text, avatarProps, name, profession, isVisible }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const stars = ref.current?.getElementsByTagName('svg') ?? [];
    for (let i = 0; i < stars.length; i++) {
      stars[i].style.transitionDelay = `${i * 100}ms`;
    }
  }, []);

  return (
    <div ref={ref} className='flex min-w-[320px] flex-col items-center gap-4'>
      <Rate
        value={isVisible ? 5 : 0}
        className={clsxMerge(
          `md[&>div>svg]:duration-500 [&>div>svg]:size-5 [&>div>svg]:transition-[stroke,fill] [&>div>svg]:duration-200 [&>div>svg]:ease-linear`,
          {
            '[&>div>svg]:fill-blue-700 [&>div>svg]:stroke-blue-700': isVisible,
          }
        )}
      />
      <p className='mb-4 max-w-[280px] text-center text-sm leading-tight text-white md:min-h-[88px]'>{text}</p>
      <div className='flex flex-row items-center gap-4'>
        <Avatar {...avatarProps} elevated />
        <div className='flex flex-col gap-0'>
          <p className='text-sm font-bold text-white'>{name}</p>
          <p className='text-xs text-gray-400'>{profession}</p>
        </div>
      </div>
    </div>
  );
};
